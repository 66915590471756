import Vue from 'vue'
import Vuex from 'vuex'
import _ from 'lodash'
import moment from 'moment'
import router from '@/router'
import AuthProvider from '@/providers/Auth'
const AuthResource = new AuthProvider()

import WareHousesProvider from '@/providers/WareHouses'
const WareHousesResource = new WareHousesProvider()

export default {
  namespaced: true,
  state: {
    token: null,
    refreshToken: null,
    user: null,
    branches: [],
    branch: null,
    permissions: [],
    warehouse_id: null,
    conexion_id: null,
    conexion: null,
    connections: [],
    wareHouse: null,
    wareHouses: [],
    setting: {},
    currentRoute: null,
    settingPurchase: {
      columns: [
        {
          key: 'show_details',
          label: '',
          description: '',
        },
        {
            key: "clave_Articulo",
            label: "Clave",
            visible: true,
            sortable: true,
            description: 'Clave del Articulo',
            thStyle: 'font-size: 0.7rem; outline: initial!important;'
        }, 
        {
          key: "nombre",
          label: "Articulo",
          visible: true,
          sortable: true,
          description: 'Nombre del Articulo',
          //tdClass: 'text-nowrap',
          thStyle: 'font-size: 0.7rem; outline: initial!important;'
        },  
        {
          key: "unidad_Venta",
          label: "Unidad V.",
          visible: true,
          sortable: true,
          description: 'Unidad de venta',
          //tdClass: 'text-nowrap',
          thStyle: 'font-size: 0.7rem; outline: initial!important;'
        },
        {
          key: "ultimo_Costo",
          label: "Costo",
          visible: true,
          sortable: true,
          description: 'Ultimo Costo',
          thStyle: 'font-size: 0.7rem; outline: initial!important;'
        },      
        {

          //VENTA MOSTRADOR PASA A ROTACION PUNTO VENTA
            // = existencia almacen / (rotacion pv + totacion v)
            key: "rotacion_Pv",
            label: "Rotación PV",
            visible: true,
            sortable: true,
            description: 'Rotación Punto Venta',
            thStyle: 'font-size: 0.7rem; outline: initial!important;'
        },
        {
            key: "rotacion_Ve",
            label: "Rotación Ventas",
            visible: true,
            sortable: true,
            description: 'Rotación Ventas',
            thStyle: 'font-size: 0.7rem; outline: initial!important;'
        },
        {
            key: "devoluciones_Totales",
            label: "Devoluciones",
            visible: true,
            sortable: true,
            description: 'Devoluciones Totales',
            thStyle: 'font-size: 0.7rem; outline: initial!important;'
        },
        {
            key: "salidas_Totales",
            label: "Salidas Por Meses",
            visible: true,
            sortable: true,
            description: 'Salidas Totales',
            thStyle: 'font-size: 0.7rem; outline: initial!important;'
        },
        {
            key: "rotacion_Pb_Ve_Proyectada",
            label: "Rotación Proyectada",
            visible: true,
            sortable: true,
            description: 'Rotación Proyectada',
            thStyle: 'font-size: 0.7rem; outline: initial!important;'
        },
        {
            key: "existencia_Almacen",
            label: "Existencia Almacen",
            visible: true,
            sortable: true,
            description: 'Existencia Almacén',
            thStyle: 'font-size: 0.7rem; outline: initial!important;'
        },
        {
            key: "existencia_Total",
            label: "Existencia Total",
            visible: false,
            sortable: true,
            description: 'Existencia Total',
            thStyle: 'font-size: 0.7rem; outline: initial!important;'
        },
        // {
        //   key: "existencia_Almacen",
        //   label: "Existencia Almacenes",
        //   visible: true,
        //   sortable: true,
        //   description: 'Existencia de Almacenes',
        //   thStyle: 'font-size: 0.7rem; outline: initial!important;'
        // },
        {
          key: "existencia_Sucursales",
          label: "Existencia Sucursales",
          visible: true,
          sortable: true,
          description: 'Existencia de Sucursales',
          thStyle: 'font-size: 0.7rem; outline: initial!important;'
        },
        {
          key: "total_meses",
          label: "Total Meses",
          visible: true,
          sortable: true,
          description: 'Total Meses',
          thStyle: 'font-size: 0.7rem; outline: initial!important;'
        },
        {
            key: "fecha_Actualizacion",
            label: "Ult. Actualización",
            visible: true,
            sortable: true,
            description: 'Última Actualización',
            thStyle: 'font-size: 0.7rem; outline: initial!important;'
        },
        {
            key: "articulo_Id",
            label: "Articulo ID",
            visible: true,
            sortable: true,
            description: 'Articulo ID',
            thStyle: 'font-size: 0.7rem; outline: initial!important;'
        },
      ],
      buy_from: moment().add(-6, 'months').format('YYYY-MM-DD'),
      low_price: 5,
      low_month: 4,
      high_price: 1000,
      high_month: 1,
      middle_month: 2.5,
    },
    lastUpdatePurchase: null,
    articles: [],
    isRecalculateRotations: false,
    isRecalculateRotationEnd: false,
    isErrorOnRecalculateRotation: false,
    hourLimits: 12,
    isAdministrator: false,
    isAuthenticated: false,
    logisticSetting: {
      descendent: false,
      orderColumns: [],
      descendentAssigned: false,
      orderColumnsAssigneds: [],
      descendentClosed: false,
      orderColumnsClosed: [],
    },
    inputFocused: false, // Variable para saber si el input esta enfocado
    rol_Clave_Art_Proveedor: 382569,
    rolAutorizador: "9da2306d-48fc-4359-af70-27fb9eeb5440",
    rolSuperCashier: "01ab07a3-50bf-483c-9fb0-b21c67b1b570",
    rolCashier: "6c72a0d5-7a67-43f4-81ff-1ec274c9a10b",
    rolPatio: "5c1e9a77-e7a1-40fe-ba71-6d5c65cea4e0",
    rolGerente: "7714b05a-aa32-4b9c-9ca1-83036bac0b5d",
    rolAdministrator: "849fb91e-cab4-4894-a1c3-aedf03b47c34",
    rolDirector: "2d7f49c8-ad48-4c4f-8def-fb06fc68143c",
    rolAuditor: "d5b8906f-b8a3-4072-b18e-5a96757640a0",
    rolLogistica: "18d21165-b649-4245-9cce-cc4bffa61d0b",
    roleEncargadoPatio: 'ac1765c2-1473-44eb-9031-b91e40323bec',
    roleEncargadoRutas: 'be1ceec2-daf3-4bb8-8a3f-7a3a40e7659e',
    userIdAmdmin: '3ced1e3f-b935-4435-9746-9f37032b6751',
    roleAdministrative: '3deeca04-b86b-4b1f-9b33-39b1fe6c3cfe',
    roleSeller: "16a3b208-9b6a-4de4-a1a3-8310fc2879f5",
    isImpersonate: false,
    impersonate: {
      userId: null,
      sucursalId: null,
      idEmpresa: null,
    },
    filterOnDashboardSeller: {
      key: null,
      label: null,      
    },
    sellers: [],
    vendedor_Id: null,
    isRefreshDashboard: false,
    deviceId: null,
  },
  getters: {
    getRolDirector: state => state.rolDirector,
    getIsRoleSeller: state => state.roleSeller,
    getIsRoleAdministrative: state => state.roleAdministrative,
    getUserIdAdmin: state => state.userIdAmdmin,
    getIsRoutingManager: state => state.roleEncargadoRutas,
    getIsImpersonate: state => state.isImpersonate,
    getImpersonate: state => state.impersonate,
    getAutorizatorRol: state => state.rolAutorizador,
    getIsLogisticRol: state => state.rolLogistica,
    getSuperCashierRol: state => state.rolSuperCashier,
    getCashierRol: state => state.rolCashier,
    getPatioRol: state => state.rolPatio,
    getGerenteRol: state => state.rolGerente,
    getAdministratorRol: state => state.rolAdministrator,
    getAuditorRol: state => state.rolAuditor,
    getEncargadoPatioRol: state => state.roleEncargadoPatio,
    getToken: state => state.token,
    getRole: state => state.user.rol,
    getUser: state => state.user,
    getBranches: state => state.branches,
    getBranch: state => state.branch,
    getPermissions: state => state.permissions,
    getConexionId: state => state.conexion_id,
    getWarehouseId: state => state.warehouse_id,
    getConexion: state => state.conexion,
    getWarehouse: state => state.wareHouse,
    getConnections: state => state.connections,
    getRefreshToken: state => state.refreshToken,
    getSetting: state => state.setting,
    getCurrentRoute: state => state.currentRoute,
    getSettingPurchase: state => state.settingPurchase,
    getLastUpdatePurchase: state => state.lastUpdatePurchase,
    getArticles: state => state.articles,
    getIsRecalculateRotations: state => state.isRecalculateRotations,
    getIsRecalculateRotationEnd: state => state.isRecalculateRotationEnd,
    getIsErrorOnRecalculateRotation: state => state.isErrorOnRecalculateRotation,
    getHourLimits: state => state.hourLimits,
    getWareHouses: state => state.wareHouses,
    getIsAdministrator: state => state.isAdministrator,
    getIsAuthenticated: state => state.isAuthenticated,
    getLogisticSetting: state => state.logisticSetting,
    getIsInputFocused: state => state.inputFocused,
    getRolClaveArtProveedor: state => state.rol_Clave_Art_Proveedor,
    getFilterOnDashboardSeller: state => state.filterOnDashboardSeller,
    getSellers: state => state.sellers,
    getSellerId: state => state.vendedor_Id,
    getIsRefreshDashboard: state => state.isRefreshDashboard,
    getDeviceId: state => state.deviceId,
  },
  mutations: {
    SET_DEVICE_ID(state, deviceId) {
      state.deviceId = deviceId
    },
    SET_IS_REFRESH_DASHBOARD(state, refresh) {
      state.isRefreshDashboard = refresh
    },
    SET_SELLER_ID (state, seller_id) {
      state.vendedor_Id = seller_id
    },
    SET_SELLERS(state, sellers) {
      state.sellers = sellers
    },
    SET_FILTER_ON_DASHBOARD_SELLER(state, filter) {
      state.filterOnDashboardSeller = filter
    },
    SET_IS_IMPERSONATE(state, isImpersonate) {
      state.isImpersonate = isImpersonate
    },
    SET_IMPERSONATE(state, impersonate) {
      state.impersonate = impersonate
    },
    SET_INPUT_FOCUS(state, value) {
      state.inputFocused = value;
    },
    SET_LOGISTIC_SETTING(state, logisticSetting) {
      state.logisticSetting = logisticSetting
    },
    SET_WAREHOUSES(state, wareHouses) {
      state.wareHouses = wareHouses
    },
    SET_HOUR_LIMITS(state, hourLimit) {
      state.hourLimits = hourLimit
    },
    SET_IS_ERROR_ON_RECALCULATE_ROTARION(state, isErrorOnRecalculateRotation) {
      state.isErrorOnRecalculateRotation = isErrorOnRecalculateRotation
    },
    SET_IS_RECALCULATE_ROTATIONS(state, isRecalculateRotations) {
      state.isRecalculateRotations = isRecalculateRotations
    },
    SET_IS_RECALCULATE_ROTATION_END(state, isRecalculateRotationEnd) {
      state.isRecalculateRotationEnd = isRecalculateRotationEnd
    },
    SET_ARTICLES(state, articles) {
      state.articles = articles
    },
    SET_LAST_UPDATE_PURCHASE(state, lastUpdatePurchase) {
      state.lastUpdatePurchase = lastUpdatePurchase
    },
    SET_SETTING_PURCHASE(state, settingPurchase) {
      state.settingPurchase = settingPurchase
    },
    SET_CURRENT_ROUTE(state, currentRoute) {
      state.currentRoute = currentRoute
    },
    SET_SETTING(state, setting) {
      if (!_.isEmpty(setting)) {
        const setValues = setting.reduce((obj, item) => {
          obj[item.clave] = _.startsWith(item.clave, 'ACTIVAR_') ? (item.valor != 0) : item.valor
          return obj
        }, {})
        state.setting = setValues
      } else {
        state.setting = setting
      }
    },
    SET_CURRENT_USER(state, user) {
      state.user = user
    },
    SET_TOKEN(state, user) {
      state.token = user
    },
    SET_REFRESH_TOKEN(state, refreshToken) {
      state.refreshToken = refreshToken
    },
    SET_BRANCHES(state, branches) {
      state.branches = branches
    },
    SET_BRANCH(state, branch) {
      state.branch = branch
    },
    SET_PERMISSIONS(state, permissions) {
      state.permissions = permissions
    },
    SET_CONEXION_ID(state, conexion_id) {
      state.conexion_id = conexion_id
    },
    SET_WAREHOUSE_ID(state, warehouse_id) {
      state.warehouse_id = warehouse_id
    },
    SET_CONEXION(state, conexion) {
      state.conexion = conexion
    },
    SET_WAREHOUSE (state, warehouse) {
      state.wareHouse = warehouse
    },
    SET_CONNECTIONS(state, connections) {
      state.connections = connections
    },
    SET_ADMINISTRATOR(state, isAdministrator) {
      state.isAdministrator = isAdministrator
    },
    SET_IS_AUTHENTICATED(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated
    }
  },
  actions: {
    GET_AUTH_TOKEN({ commit, state }, payload) {
      return new Promise((resolve, reject) => {
        AuthResource.login(payload).then(response => {
          const { data } = response
          if (data.isSuccesful) {
            localStorage.setItem('loggedIn', true)
            localStorage.setItem('auth_token', data.data.token)
            
            commit('SET_IS_AUTHENTICATED', true)
            commit('SET_TOKEN', { ...data.data })
            commit('SET_REFRESH_TOKEN', data.data)
            commit('SET_CURRENT_USER', data.data)
            commit('SET_PERMISSIONS', data.data.permisos)
            commit('SET_WAREHOUSE_ID', payload.almacen_Id)
            commit('SET_CONEXION_ID', payload.idEmpresa)
            if (data.data) {
              if (data.data.customRol.id == "849fb91e-cab4-4894-a1c3-aedf03b47c34" || data.data.customRol.id == "2d7f49c8-ad48-4c4f-8def-fb06fc68143c") { 
                commit('SET_ADMINISTRATOR', true)
              } 
              else if (data.data.customRol.id == "01ab07a3-50bf-483c-9fb0-b21c67b1b570") {
                commit('SET_WAREHOUSE_ID', 0)
              }
            }
            window.user = data.data
            return AuthResource.getSettingByCompanyId(payload.idEmpresa)
          }
          reject(response)
        }).then(setting => {
          const { data } = setting
          if(data.isSuccesful) {
            commit('SET_SETTING', data.data)
            return WareHousesResource.index()
          }
          reject(setting)
        }).then(warehouses => {
          const { data } = warehouses
          if(data.isSuccesful) {
            commit('SET_WAREHOUSES', data.data)
            resolve(warehouses)
          }
          reject(warehouses)          
        }).catch(error => {
          window.loggedIn = false
          localStorage.removeItem('authenticated')
          localStorage.removeItem('loggedIn')
          localStorage.removeItem('auth_token')          
          window.localStorage.removeItem('vuex')
          commit('SET_IS_AUTHENTICATED', false)
          commit('SET_TOKEN', null)
          commit('SET_CURRENT_USER', null)
          commit('SET_PERMISSIONS', [])
          commit('SET_WAREHOUSE_ID', null)
          commit('SET_CONEXION_ID', null)
          commit('SET_CONEXION', null)
          commit('SET_WAREHOUSE', {})
          commit('SET_CONNECTIONS', [])
          commit('SET_BRANCH', null)
          commit('SET_REFRESH_TOKEN', null)
          commit('SET_SETTING', {})
          commit('SET_LAST_UPDATE_PURCHASE', null)
          commit('SET_ARTICLES', [])
          commit('SET_IS_RECALCULATE_ROTATIONS', false)
          commit('SET_IS_ERROR_ON_RECALCULATE_ROTARION', false)
          commit('SET_ADMINISTRATOR', false)
          commit('SET_IS_AUTHENTICATED', false)
          commit('SET_IS_IMPERSONATE', false)
          commit('SET_IMPERSONATE', {
            userId: null,
            sucursalId: null,
            empresaId: null,
          })
          commit('SET_SELLERS', [])
          commit('SET_SELLER_ID', null)
          commit('SET_FILTER_ON_DASHBOARD_SELLER', {
            key: null,
            label: null,
          })
          commit('SET_DEVICE_ID', null)
          reject(error)
        })
      })
    },
    LOGOUT({ commit, state }) {
      AuthResource.logout()
        .then(response => {
          localStorage.removeItem('authenticated')
          localStorage.removeItem('loggedIn')
          localStorage.removeItem('auth_token')
          window.localStorage.removeItem('vuex')
          commit('SET_TOKEN', null)
          commit('SET_CURRENT_USER', null)
          commit('SET_PERMISSIONS', [])
          commit('SET_WAREHOUSE_ID', null)
          commit('SET_CONEXION_ID', null)
          commit('SET_CONEXION', null)
          commit('SET_WAREHOUSE', {})
          commit('SET_CONNECTIONS', [])
          commit('SET_BRANCH', null)
          commit('SET_REFRESH_TOKEN', null)
          commit('SET_SETTING', {})
          commit('SET_LAST_UPDATE_PURCHASE', null)
          commit('SET_ARTICLES', [])
          commit('SET_IS_RECALCULATE_ROTATIONS', false)
          commit('SET_IS_ERROR_ON_RECALCULATE_ROTARION', false)
          commit('SET_SETTING_PURCHASE',  null)
          commit('SET_WAREHOUSES', [])
          commit('SET_ADMINISTRATOR', false)
          commit('SET_IS_AUTHENTICATED', false)
          commit('SET_IS_IMPERSONATE', false)
          commit('SET_IMPERSONATE', {
            userId: null,
            sucursalId: null,
            empresaId: null,
          })
          commit('SET_SELLERS', [])
          commit('SET_SELLER_ID', null)
          commit('SET_FILTER_ON_DASHBOARD_SELLER', {
            key: null,
            label: null,
          })
          commit('SET_DEVICE_ID', null)

          router.push({ name: 'login' })
        })
        .catch(error => {
          console.error(error)
          commit('SET_TOKEN', null)
          commit('SET_CURRENT_USER', null)
          commit('SET_PERMISSIONS', [])
          commit('SET_WAREHOUSE_ID', null)
          commit('SET_CONEXION_ID', null)
          commit('SET_CONEXION', null)
          commit('SET_WAREHOUSE', {})
          commit('SET_CONNECTIONS', [])
          commit('SET_BRANCH', null)
          commit('SET_REFRESH_TOKEN', null)
          commit('SET_SETTING', {})
          commit('SET_LAST_UPDATE_PURCHASE', null)
          commit('SET_ARTICLES', [])
          commit('SET_IS_RECALCULATE_ROTATIONS', false)
          commit('SET_IS_ERROR_ON_RECALCULATE_ROTARION', false)
          commit('SET_SETTING_PURCHASE',  null)
          commit('SET_WAREHOUSES', [])
          commit('SET_ADMINISTRATOR', false)
          commit('SET_IS_AUTHENTICATED', false)
          commit('SET_IS_IMPERSONATE', false)
          commit('SET_IMPERSONATE', {
            userId: null,
            sucursalId: null,
            empresaId: null,
          })
          commit('SET_SELLERS', [])
          commit('SET_SELLER_ID', null)
          commit('SET_FILTER_ON_DASHBOARD_SELLER', {
            key: null,
            label: null,
          })
          commit('SET_DEVICE_ID', null)
        })
    },
    UPDATE_WAREHOUSES({commit, state}, payload){
      commit('SET_WAREHOUSES', payload)
    },
    UPDATE_TOKEN_EXPIRED({commit, state}, payload) {
        localStorage.removeItem('authenticated')
        localStorage.removeItem('loggedIn')
        localStorage.removeItem('auth_token')
        window.localStorage.removeItem('vuex')
        commit('SET_TOKEN', null)
        commit('SET_CURRENT_USER', null)
        commit('SET_PERMISSIONS', [])
        commit('SET_WAREHOUSE_ID', null)
        commit('SET_CONEXION_ID', null)
        commit('SET_CONEXION', null)
        commit('SET_WAREHOUSE', {})
        commit('SET_CONNECTIONS', [])
        commit('SET_BRANCH', null)
        commit('SET_REFRESH_TOKEN', null)
        commit('SET_SETTING', {})
        commit('SET_LAST_UPDATE_PURCHASE', null)
        commit('SET_ARTICLES', [])
        commit('SET_IS_RECALCULATE_ROTATIONS', false)
        commit('SET_IS_ERROR_ON_RECALCULATE_ROTARION', false)
        commit('SET_SETTING_PURCHASE',  null)
        commit('SET_WAREHOUSES', [])
        commit('SET_IMPERSONATE', {
          userId: null,
          sucursalId: null,
          empresaId: null,
        })
        router.push({ name: 'login' })
    },
    UPDATE_BRANCHES({ commit, state }, payload) {
      commit('SET_BRANCHES', payload)
    },
    UPDATE_BRANCH({ commit, state }, payload) {
      commit('SET_BRANCH', payload)
    },
    UPDATE_PERMISSIONS({commit, state}, payload) {
      commit('SET_PERMISSIONS', payload)
    },
    UPDATE_CONEXION_ID({commit, state}, payload) {
      commit('SET_CONEXION_ID', payload)
    },
    UPDATE_WAREHOUSE_ID({commit, state}, payload) {
      commit('SET_WAREHOUSE_ID', payload)
    },
    UPDATE_CONEXION({commit, state}, payload) {
      commit('SET_CONEXION', payload)
    },
    UPDATE_WAREHOUSE({commit, state}, payload) {
      commit('SET_WAREHOUSE', payload)
    },
    UPDATE_CONNECTIONS({commit, state}, payload) {
      commit('SET_CONNECTIONS', payload)
    },
    UPDATE_REFRESH_TOKEN({commit, state}, payload) {
      commit('SET_REFRESH_TOKEN', null)
    },
    UPDATE_SETTING({commit, state}, payload) {
      commit('SET_SETTING', payload)
    },
    UPDATE_CURRENT_ROUTE({commit, state}, payload) {
      commit('SET_CURRENT_ROUTE', payload)
    },
    UPDATE_SETTING_PURCHASE({commit, state}, payload) {
      commit('SET_SETTING_PURCHASE', payload)
    },
    UPDATE_LAST_UPDATE_PURCHASE({commit, state}, payload) {
      commit('SET_LAST_UPDATE_PURCHASE', payload)
    },
    UPDATE_ARTICLES({commit, state}, payload) {
      commit('SET_ARTICLES', payload)
    },
    UPDATE_IS_RECALCULATE_ROTATIONS({commit, state}, payload) {
      commit('SET_IS_RECALCULATE_ROTATIONS', payload)
    },
    UDPDATE_IS_RECALCULATE_RATION_END({commit, state}, payload) {
      commit('SET_IS_RECALCULATE_ROTATION_END', payload)
    },
    UPDATE_IS_ERROR_ON_RECALCULATE_ROTATION({commit, state}, payload) {
      commit('SET_IS_ERROR_ON_RECALCULATE_ROTARION', payload)
    },
    UPDATE_HOUR_LIMIT({commit, state}, payload) {
      commit('SET_HOUR_LIMITS', payload)
    },
    UPDATE_IS_ADMINISTRATOR({commit, state}, payload) {
      commit('SET_ADMINISTRATOR', payload)
    },
    UPDATE_LOGISTIC_SETTING({commit, state}, payload) {
      commit('SET_LOGISTIC_SETTING', payload)
    },
    UDPDATE_INPUT_FOCUS({ commit }, value) {
      commit('SET_INPUT_FOCUS', value);
    },
    UPDATE_IMPERSONATE({ commit }, impersonate) {
      commit('SET_IMPERSONATE', impersonate)
    },
    UPDATE_IS_IMPERSONATE({ commit }, isImpersonate) {
      commit('SET_IS_IMPERSONATE', isImpersonate)
    },
    UPDATE_FILTER_ON_DASHBOARD_SELLER({ commit}, filter) {
      commit('SET_FILTER_ON_DASHBOARD_SELLER', filter)
    },
    UPDATE_SELLERS({ commit }, sellers) {
      commit('SET_SELLERS', sellers)
    },
    UPDATE_SELLER_ID({ commit }, seller_id) {
      commit('SET_SELLER_ID', seller_id)
    },
    UPDATE_DEVICE_ID({ commit }, deviceId) {
      commit('SET_DEVICE_ID', deviceId)
    },
  },
}
