import _ from "lodash";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import momentTimezone from 'moment-timezone'
import moment from 'moment'
const TIMEZONE = 'America/Matamoros'
export default {
    methods: {
        generateDaysOfMonth(year, month) { // Función para generar los días de un mes específico
            const daysInMonth = moment(`${year}-${month}`, "YYYY-MM").daysInMonth();
            let days = [];
          
            for (let day = 1; day <= daysInMonth; day++) {
              days.push({
                month: moment(`${year}-${month}`, "YYYY-MM").format("MMM"), // Mes abreviado
                day: moment(`${year}-${month}-${day}`, "YYYY-MM-DD").format("DD"), // Día formateado
                sales: 0 // Inicialmente sin ventas
              });
            }          
            return days;
        },
        mergeSalesWithDays(days, salesData) {
            days.forEach(day => {
              const sale = salesData.find(sale => sale.dia === day.day);
              if (sale) {
                day.sales = sale.venta_Importe;  // Si hay ventas para ese día, las asigna
              }
            });
        },
        filterByDates(filter = 'current_month') {
            let fechaIni, fechaFin;
            switch(filter) {
                case 'current_month':
                    fechaIni = moment().startOf('month').format('YYYY-MM-DD');
                    fechaFin = moment().endOf('month').format('YYYY-MM-DD');
                    break;
                case 'previous_month':
                    fechaIni = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
                    fechaFin = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
                    break;
                case 'current_month_last_year':
                    // Restamos 1 año, pero el mes sigue siendo el mismo
                    fechaIni = moment().subtract(1, 'year').startOf('month').format('YYYY-MM-DD');
                    fechaFin = moment().subtract(1, 'year').endOf('month').format('YYYY-MM-DD');
                    break;
                case 'previous_month_last_year':
                    // Restamos 1 año, pero también 1 mes adicional
                    fechaIni = moment().subtract(1, 'year').subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
                    fechaFin = moment().subtract(1, 'year').subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
                    break;
                case 'today':
                    fechaIni = moment().format('YYYY-MM-DD');
                    fechaFin = moment().format('YYYY-MM-DD');
                    break;
                case 'next_7_days':
                    fechaIni = moment().format('YYYY-MM-DD');
                    fechaFin = moment().add(7, 'days').format('YYYY-MM-DD');
                    break;
                case 'next_15_days': 
                    fechaIni = moment().format('YYYY-MM-DD');
                    fechaFin = moment().add(15, 'days').format('YYYY-MM-DD');
                    break;
                case 'next_30_days':
                    fechaIni = moment().format('YYYY-MM-DD');
                    fechaFin = moment().add(30, 'days').format('YYYY-MM-DD');
                    break;
                case 'overdue':
                    fechaIni = moment().subtract(1, 'year').format('YYYY-MM-DD');
                    fechaFin = moment().subtract(1, 'year').format('YYYY-MM-DD');
                    break;
                case 'overdue_last_7_days':
                    fechaIni = moment().subtract(7, 'days').format('YYYY-MM-DD');
                    fechaFin = moment().subtract(7, 'days').format('YYYY-MM-DD');                   
                    break;
                case 'overdue_last_month':
                    fechaIni = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
                    fechaFin = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
                    break;
                case 'overdue_more_30':
                    fechaIni = moment().subtract(30, 'days').format('YYYY-MM-DD');
                    fechaFin = moment().subtract(30, 'days').format('YYYY-MM-DD');                    
                    break;
                case 'overdue_more_60':
                    fechaIni = moment().subtract(60, 'days').format('YYYY-MM-DD');
                    fechaFin = moment().subtract(60, 'days').format('YYYY-MM-DD');                    
                    break;
                case 'overdue_more_90':
                    fechaIni = moment().subtract(90, 'days').format('YYYY-MM-DD');
                    fechaFin = moment().subtract(90, 'days').format('YYYY-MM-DD');                    
                    break;
                case 'overdue_more_90_plus':
                    fechaIni = moment().subtract(90, 'days').format('YYYY-MM-DD');
                    fechaFin = moment().subtract(90, 'days').format('YYYY-MM-DD');                    
                    break;
                default:
                    fechaIni = moment().startOf('month').format('YYYY-MM-DD');
                    fechaFin = moment().endOf('month').format('YYYY-MM-DD');
                    return;
            }
            return {
                fechaIni: fechaIni,
                fechaFin: fechaFin,
            }
        },
        formatTableDataExport(data, fields) {
            // Formatear los encabezados de las columnas
            const headerRow = fields.map(field => field.label).join('\t');

            // Formatear los datos de la tabla como texto
            const dataRows = data.map(row => fields.map(field => row[field.key]).join('\t')).join('\n');

            // Unir encabezados y datos
            return `${headerRow}\n${dataRows}`;
        },
        getColorOnBinnale(type){
            const bitacoraColors = {
                "ENTREGA PROGRAMADA": "primary",
                "CAMBIO CHOFER": "info",
                "CERRADO CON PEDIENTE": "warning",
                "CERRADO CON SALIDAS": "success",
                "REAPERTURA FACTURA": "danger",
                "SALIDA CON RUTA CERRADA": "dark",
                "IMPRESION HOJA CONTROL": "secondary",
                "CAMBIO ALMACEN LOGISTICA": "light",
                "DEVOLUCION": "danger",
                "DOCUMENTO RECIBIDO": "info",
            }
            return bitacoraColors[type] || "primary";
        },
        customDate (date, format = 'MMMM Do YYYY, h:mm:ss a') {
            moment.locale('es')    
            moment.tz.setDefault(TIMEZONE)        
            return moment(date).format(format)
        },
        currentDate(format = 'MMMM Do YYYY, h:mm:ss a') {
            moment.locale('es')
            moment.tz.setDefault(TIMEZONE)
            return moment().format(format)
        },
        findSetting(key) {
            const setting = store.getters['auth/getSetting']
            return setting[key]
        },
        canAccess(id) {            
            if (id == 0) return true //only dashboard
            const permission = store.getters['auth/getPermissions']            
            return _.find(permission, (p) => p.permiso_Id === id) ? true : false
        },
        customFormatDate(value = '', format = 'll') {
            moment.tz.setDefault(TIMEZONE)

            if (value == '') {
                return moment().locale('es').format(format)
            }
            return moment(value).locale('es').format(format)
             
        },
        // dateHuman(date, hours = 0) {
        //     moment.locale('es')
        //     moment.tz.setDefault(TIMEZONE)
        //     const before = moment(date, 'YYYY-MM-DD H:i:s')
        //     const now = moment(new Date(), 'YYYY-MM-DD H:i:s')
        //     if (hours > 0) {
        //         return moment(before).add(12, 'hours').fromNow()
        //     }
        //     return moment(before).fromNow()
        // },
        dateHuman(date, date2, hours = 0) {
            moment.locale('es')
            moment.tz.setDefault(TIMEZONE)
            const before = moment(date, 'YYYY-MM-DD H:i:s')
            const now = moment(date2, 'YYYY-MM-DD H:i:s')
            if (hours > 0) {
                return moment(before).add(12, 'hours').fromNow()
            }
            return moment(before).fromNow()
        },
        customDateHuman(date1, date2, hours = 0) {
            moment.tz.setDefault(TIMEZONE)

           // Calcular la diferencia de tiempo en milisegundos
            const diff = moment(date1).diff(moment(date1));
            
            // Mostrar el tiempo transcurrido de manera humanizada
            return this.$moment.duration(diff).humanize();
        },
        mostrarTiempoTranscurrido(fechaInicio, fechaFinal, timezone = 'America/Matamoros') {
            moment.locale('es');

            // Aplica la zona horaria a ambas fechas
            const start = moment.tz(fechaInicio, timezone);
            const end = moment.tz(fechaFinal, timezone);
            
            // Clonar la fecha de inicio para no modificar el original
            let tempStart = start.clone();
            
            // Calcular la diferencia de tiempo en cada unidad
            const years = end.diff(tempStart, 'years');
            tempStart.add(years, 'years');  // Ajustar el tiempo restante
            
            const months = end.diff(tempStart, 'months');
            tempStart.add(months, 'months');  // Ajustar el tiempo restante
            
            const days = end.diff(tempStart, 'days');
            tempStart.add(days, 'days');  // Ajustar el tiempo restante
            
            const hours = end.diff(tempStart, 'hours');
            tempStart.add(hours, 'hours');  // Ajustar el tiempo restante
            
            const minutes = end.diff(tempStart, 'minutes');
            tempStart.add(minutes, 'minutes');  // Ajustar el tiempo restante
            
            const seconds = end.diff(tempStart, 'seconds');
            
            // Formatear el tiempo transcurrido
            let tiempoTranscurrido = '';
            
            if (years > 0) {
                tiempoTranscurrido += `${years} año${years > 1 ? 's' : ''} `;
            }
            if (months > 0) {
                tiempoTranscurrido += `${months} mes${months > 1 ? 'es' : ''} `;
            }
            if (days > 0) {
                tiempoTranscurrido += `${days} día${days > 1 ? 's' : ''} `;
            }
            if (hours > 0) {
                tiempoTranscurrido += `${hours} hora${hours > 1 ? 's' : ''} `;
            }
            if (minutes > 0) {
                tiempoTranscurrido += `${minutes} minuto${minutes > 1 ? 's' : ''} `;
            }
            if (seconds > 0) {
                tiempoTranscurrido += `${seconds} segundo${seconds > 1 ? 's' : ''} `;
            }
            
            return tiempoTranscurrido.trim() || '0 segundos';
        },
        
               
        __mostrarTiempoTranscurrido(fechaInicio, fechaFinal, timezone = 'America/Matamoros') {
            moment.locale('es');
        
            // Aplica la zona horaria a ambas fechas
            const start = moment.tz(fechaInicio, timezone);
            const end = moment.tz(fechaFinal, timezone);
        
            // Calcular la diferencia de tiempo en cada unidad
            const years = end.diff(start, 'years');
            start.add(years, 'years');  // Ajustar el tiempo restante
        
            const months = end.diff(start, 'months');
            start.add(months, 'months');  // Ajustar el tiempo restante
        
            const days = end.diff(start, 'days');
            start.add(days, 'days');  // Ajustar el tiempo restante
        
            const hours = end.diff(start, 'hours');
            start.add(hours, 'hours');  // Ajustar el tiempo restante
        
            const minutes = end.diff(start, 'minutes');
            start.add(minutes, 'minutes');  // Ajustar el tiempo restante
        
            const seconds = end.diff(start, 'seconds');
        
            // Formatear el tiempo transcurrido
            let tiempoTranscurrido = '';
        
            if (years > 0) {
                tiempoTranscurrido += `${years} año${years > 1 ? 's' : ''} `;
            }
            if (months > 0) {
                tiempoTranscurrido += `${months} mes${months > 1 ? 'es' : ''} `;
            }
            if (days > 0) {
                tiempoTranscurrido += `${days} día${days > 1 ? 's' : ''} `;
            }
            if (hours > 0) {
                tiempoTranscurrido += `${hours} hora${hours > 1 ? 's' : ''} `;
            }
            if (minutes > 0) {
                tiempoTranscurrido += `${minutes} minuto${minutes > 1 ? 's' : ''} `;
            }
            if (seconds > 0) {
                tiempoTranscurrido += `${seconds} segundo${seconds > 1 ? 's' : ''} `;
            }
        
            return tiempoTranscurrido.trim() || '0 segundos';
        },
        mostrarTiempoTranscurrido_(fechaInicio, fechaFinal) {            
            moment.locale('es')
            //moment.tz.setDefault(TIMEZONE)            
            const start = moment(fechaInicio)
            const end = moment(fechaFinal)
            const diff = end.diff(start);
      
            const duration = moment.duration(diff);
      
            const years = duration.years();
            const months = duration.months();
            const days = duration.days();
            const hours = duration.hours();
            const minutes = duration.minutes();
            const seconds = duration.seconds();
      
            let tiempoTranscurrido = '';
      
            if (years > 0) {
              tiempoTranscurrido += `${years} año${years > 1 ? 's' : ''} `;
            }
            if (months > 0) {
              tiempoTranscurrido += `${months} mes${months > 1 ? 'es' : ''} `;
            }
            if (days > 0) {
              tiempoTranscurrido += `${days} día${days > 1 ? 's' : ''} `;
            }
            if (hours > 0) {
              tiempoTranscurrido += `${hours} hora${hours > 1 ? 's' : ''} `;
            }
            if (minutes > 0) {
              tiempoTranscurrido += `${minutes} minuto${minutes > 1 ? 's' : ''} `;
            }
            if (seconds > 0) {
              tiempoTranscurrido += `${seconds} segundo${seconds > 1 ? 's' : ''} `;
            }
      
            return tiempoTranscurrido.trim();
          },
        _mostrarTiempoTranscurrido(fechaInicio, fechaFinal) {
            // Calcular la diferencia de tiempo en milisegundos
            const diffMs = this.$moment(fechaFinal).diff(this.$moment(fechaInicio));
          
            // Obtener la duración en días, horas, minutos y segundos
            const duration = this.$moment.duration(diffMs);
          
            // Construir la cadena de tiempo transcurrido en formato humano
            let tiempoTranscurrido = "";
          
            if (duration.days() > 0) {
                tiempoTranscurrido += `${duration.days()} día${duration.days() > 1 ? 's' : ''} `;
            }
            
            if (duration.hours() > 0) {
                tiempoTranscurrido += `${duration.hours()} hora${duration.hours() > 1 ? 's' : ''} `;
            }
          
            if (duration.minutes() > 0) {
                tiempoTranscurrido += `${duration.minutes()} minuto${duration.minutes() > 1 ? 's' : ''} `;
            }
          
            if (duration.seconds() > 0) {
                tiempoTranscurrido += `${duration.seconds()} segundo${duration.seconds() > 1 ? 's' : ''} `;
            }
          
            // Eliminar el espacio adicional al final y devolver la cadena
            return tiempoTranscurrido.trim();
        },        
       
        formatDate(value, formatting = { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false }) {
            if (!value)
                return value

            return new Intl.DateTimeFormat('es-ES', formatting).format(new Date(value))
        },
        formatCurrency(value) {
            if (!value) return 0;
          
            const locale = 'es-MX'; // Ajusta el idioma y región según tu necesidad
            const options = {
              style: 'currency',
              currency: 'MXN', // Ajusta la moneda según tu necesidad
              minimumFractionDigits: 2, // Ajusta la cantidad de decimales
            };
          
            const numberFormatter = new Intl.NumberFormat(locale, options);
            return numberFormatter.format(value);
        },
        numberFormat(number, fixed = 2) {
            return parseFloat(number).toFixed(fixed)
            // return number
            //     ? number === "N/A"
            //         ? number
            //         : `${parseFloat(number).toFixed(fixed)}`
            //     : number
            //     ? number === "N/A"
            //         ? number
            //         : parseFloat(number).toFixed(fixed)
            //     : 0;
        },
        formatoPorcentaje(valor) {
            const result = valor * 100 / 100
            return Math.round(result).toFixed(2) + "%";
        },
        getFirstValidationError(errorBag) {
            if (errorBag.response.data.errors) {
                const errors = Object.values(errorBag.response.data.errors);
                const firstErrorArray = errors.find(errorArray => errorArray.length > 0);
                if (firstErrorArray && firstErrorArray.length > 0) {
                    return firstErrorArray[0];
                }
            }
            return errorBag.response.data.title || "Error en la solicitud.";
        },
        avatarText2(value) {
            if (!value) return ''
            const nameArray = value.split(' ')
            return nameArray.map(word => word.charAt(0).toUpperCase()).join('')
        },
        // Método para convertir la cadena base64 en una URL de objeto
        getImageUrl(base64String) {
            // Convertir la cadena base64 en un objeto Blob
            const blob = this.base64ToBlob(base64String, 'image/jpeg');
            // Crear una URL de objeto para el Blob
            return URL.createObjectURL(blob);
        },
        base64ToBlob(base64String, contentType) {
            const byteCharacters = atob(base64String);
            const byteArrays = [];
            for (let offset = 0; offset < byteCharacters.length; offset += 512) {
              const slice = byteCharacters.slice(offset, offset + 512);
              const byteNumbers = new Array(slice.length);
              for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
              }
              const byteArray = new Uint8Array(byteNumbers);
              byteArrays.push(byteArray);
            }
            return new Blob(byteArrays, { type: contentType });
        },
        handleResponseErrors(e) {
            let errorMessage = e.message; // Mensaje de error predeterminado
            if (e.response) {
                const { status } = e.response;
                const validationError = this.getFirstValidationError(e);
                switch (status) {
                    case 400:
                    case 401:
                    case 403:
                    case 404:
                    case 405:
                    case 422:
                    case 500:
                        errorMessage = validationError || "Error en la solicitud.";
                        break;
                }
            }
            this.danger(errorMessage)
        },
        success(text, title = "¡Éxito!", icon = "CheckIcon") {
            this.$toast({
                component: ToastificationContent,
                props: {
                    position: "top-right",
                    variant: "success",
                    icon,
                    title,
                    text,
                },
            });
        },
        info(text, title = "¡Información!", icon = "InfoIcon") {
            this.$toast({
                component: ToastificationContent,
                props: {                    
                    variant: "info",
                    icon,
                    title,
                    text,
                },
            },{
                position: "top-center",
            });
        },
        warning(text, title = "¡Advertencia!", icon = "AlertTriangleIcon") {
            this.$toast({
                component: ToastificationContent,
                props: {
                    position: "top-right",
                    variant: "warning",
                    icon,
                    title,
                    text,
                },
            });
        },
        danger(text, title = "¡Error!", icon = "XIcon", position = 'top-right', timeout = null) {
            if (timeout) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        variant: "danger",
                        icon,
                        title,
                        text,
                    },
                }, 
                {
                    position,
                    timeout
                });
            } else {
                // this.$toast({
                //     component: ToastificationContent,
                //     props: {
                //         position,
                //         variant: "danger",
                //         icon,
                //         title,
                //         text,
                //     },
                // });
                this.$toast({
                    component: ToastificationContent,
                    props: {
                      title,
                      icon,
                      text,
                      variant: 'danger',
                    },
                  },
                  {
                    position,
                  })
            }
        },
        resolvePaginationTranslate(dataMeta) {
            return `Mostrando ${dataMeta.from} a ${dataMeta.to} de ${dataMeta.of} entradas`
        },
        setDeliveryPercent(item) {
            if (item && item.articulos) {
                const pendiente = item.articulos.reduce((carry, i) => carry + (i.pendiente * 1), 0);
                const total = item.articulos.reduce((carry, i) => carry + (i.um_Total * 1), 0);
                const entregado = item.articulos.reduce((carry, i) => carry + (i.entregado * 1), 0);
                const devoluciones = item.articulos.reduce((carry, i) => carry + (i.devoluciones * 1), 0);

                if (total === devoluciones) {
                    return 100
                }
            
                if (entregado === 0) {
                    return 0
                }

                if (pendiente === 0) {
                    return 100
                }

                if (pendiente > 0){   
                    const porcentaje = (entregado / total) * 100;             
                    return porcentaje % 1 === 0 
                    ? porcentaje.toFixed(0)  // Sin decimales si el número es entero
                    : porcentaje.toFixed(2); // Con hasta dos decimales si el número no es entero
                }
            }
            return 0;
        },
        setColorClass(item) {
            // if (!item && !item.articulos) return 'primary';            
            
            if (item && item.articulos) {
                const entregado = item.articulos.reduce((carry, i) => carry + (i.entregado * 1), 0);
                const pendiente = item.articulos.reduce((carry, i) => carry + (i.pendiente * 1), 0);
                const total = item.articulos.reduce((carry, i) => carry + (i.um_Total * 1), 0);
                const devoluciones = item.articulos.reduce((carry, i) => carry + (i.devoluciones * 1), 0);
                const porcentaje = (entregado / total) * 100;

                if (total === devoluciones) {
                    return 'success'
                }            
            
                if (entregado <= 0){ 
                    return 'danger';
                } else if (pendiente == 0) {
                    return 'success';
                } else if (porcentaje >= 0 && porcentaje <= 33) {
                    return 'danger'; // Rojo
                } else if (porcentaje >= 33 && porcentaje <= 66) {
                    return 'orange'; // Naranja
                } else if (porcentaje >= 66 && porcentaje <= 99) {
                    return 'warning'; // Amarillo
                } else if (porcentaje === 100) {
                    return 'success'; // Verde
                } else {
                    return 'secondary'; // Color por defecto si es necesario
                }
            }
            return 'primary';
        },
    }
}