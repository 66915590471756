export default [
    {
        path: '/apps/inventories/provisional-income',
        name: 'apps-inventories-provisional-income',
        component: () => import('@/views/pages/inventories/ProvisionalIncome.vue'),
        meta: {
            pageTitle: 'Ingresos Provisionales',
            breadcrumb: [
                {
                    text: 'Dashboard',
                },
                {
                    text: 'Ingresos Provisionales',
                    active: true,
                },
            ],
        },
    },

    {
        path: '/apps/inventories/provisional-income-add',
        name: 'apps-inventories-provisional-income-add',
        component: () => import('@/views/pages/inventories/ProvisionalIncomeAdd.vue'),
        meta: {
            pageTitle: 'Agregar Ingreso Provisional',
            breadcrumb: [
                {
                    text: 'Dashboard',
                },
                {
                    text: 'Ingresos Provisionales',
                    active: true,
                },
            ],
        },
    },

    {
        path: '/apps/inventories/transfer-vouchers',
        name: 'apps-inventories-transfer-vouchers',
        component: () => import('@/views/pages/inventories/TransferVouchers.vue'),
        meta: {
            pageTitle: 'Vales de Traspasos',
            breadcrumb: [
                {
                    text: 'Dashboard',
                },
                {
                    text: 'Vales de Traspaso',
                    active: true,
                },
            ],
        },
    },

    {
        path: '/apps/inventories/transfer-vouchers/add',
        name: 'apps-inventories-transfer-vouchers-add',
        component: () => import('@/views/pages/inventories/TransferVouchersAdd.vue'),
        meta: {
            pageTitle: 'Solicitar Material desde Cotización',
            breadcrumb: [
                {
                    text: 'Dashboard',
                },
                {
                    text: 'Solicitar Vales de Traspaso',
                    active: true,
                },
            ],
        },
    },

    {
        path: '/apps/inventories/transfer-vouchers/send',
        name: 'apps-inventories-transfer-vouchers-send',
        component: () => import('@/views/pages/inventories/TransferVouchersSend.vue'),
        meta: {
            pageTitle: 'Enviar Material',
            breadcrumb: [
                {
                    text: 'Dashboard',
                },
                {
                    text: 'Vales de Traspaso',
                    active: true,
                },
            ],
        },
    },

    {
        path: '/apps/inventories/transfer-vouchers/receive',
        name: 'apps-inventories-transfer-vouchers-receive',
        component: () => import('@/views/pages/inventories/TransferVouchersReceive.vue'),
        meta: {
            pageTitle: 'Recibir Material',
            breadcrumb: [
                {
                    text: 'Dashboard',
                },
                {
                    text: 'Vales de Traspaso',
                    active: true,
                },
            ],
        },
    },
]
