<template>
	<div id="app" class="h-100" :class="[skinClasses]">
		<component :is="layout">
			<router-view />
		</component>

		<scroll-to-top v-if="enableScrollToTop && !isViewPurchase" />
	</div>
</template>

<script>
import { HubConnectionBuilder } from "@microsoft/signalr";
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

import AuthProvider from '@/providers/Auth'
const AuthResource = new AuthProvider()

export default {
	components: {

		// Layouts
		LayoutHorizontal,
		LayoutVertical,
		LayoutFull,

		ScrollToTop,
	},
	data() {
		return {
			hubConnection: null,
			messages: []
		}
	},
	// ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
	// Currently, router.currentRoute is not reactive and doesn't trigger any change
	computed: {
		layout() {
			if (this.$route.meta.layout === 'full') return 'layout-full'
			return `layout-${this.contentLayoutType}`
		},
		contentLayoutType() {
			return this.$store.state.appConfig.layout.type
		},
		isViewPurchase() {
			return store.getters['auth/getUser'] ? store.getters['auth/getCurrentRoute'] === 'apps-purchase' : false
		},
		isLogisticRol() {
			return store.getters['auth/getUser'] ? store.getters['auth/getUser'].customRol.id == store.getters['auth/getIsLogisticRol'] : false
		},
		isAuthenticate() {
			return store.getters['auth/getIsAuthenticated'] || false
		}
	},
	created() {
		this.createConnection();
	},
	beforeCreate() {
		// Set colors in theme
		const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

		// eslint-disable-next-line no-plusplus
		for (let i = 0, len = colors.length; i < len; i++) {
			$themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
		}

		// Set Theme Breakpoints
		const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

		// eslint-disable-next-line no-plusplus
		for (let i = 0, len = breakpoints.length; i < len; i++) {
			$themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
		}

		// Set RTL
		const { isRTL } = $themeConfig.layout
		document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
	},
	async mounted() {
		if (this.isAuthenticate) {
			await this.getSettings()
		}
	},
	methods: {
		async getSettings() {
			const { data } = await AuthResource.getSettingByCompanyId(1)
			if (data.isSuccesful) {
				store.commit('auth/SET_SETTING', data.data)
			}
		},
		async createConnection() {

			store.dispatch('routing/UPDATE_IS_RELOAD_ABIERTA', false)
			store.dispatch('routing/UPDATE_IS_RELOAD_EN_RUTA', false)
			store.dispatch('routing/UPDATE_IS_RELOAD_CERRADA', false)
			store.dispatch('routing/UPDATE_IS_RELOAD_CLIENTE_PASA', false)
			
			this.hubConnection = new HubConnectionBuilder()
				.withUrl(process.env.VUE_APP_API_URL + "/routingHub")
				.withAutomaticReconnect()
				.build();

			try {
				await this.hubConnection.start();
				console.log("Connection started!");

				this.hubConnection.on("ReceiveRouteStatus", (routing, message) => {
					console.log([routing, message]);
					if (routing.estatus == 'ABIERTA') {
						store.commit('routing/SET_IS_RELOAD_ABIERTA', true)
					} else if (routing.estatus == 'EN RUTA') {
						store.commit('routing/SET_IS_RELOAD_EN_RUTA', true)
					} else if (routing.estatus == 'CERRADA') {
						store.commit('routing/SET_IS_RELOAD_CERRADA', true)
					} else if (routing.estatus == 'CLIENTE PASA') {
						store.commit('routing/SET_IS_RELOAD_CLIENTE_PASA', true)
					}
				});
			} catch (err) {
				console.error(err);
			}
		},
		sendMessage(user, message) {
			this.hubConnection.invoke("SendMessage", user, message)
				.catch(err => console.error(err));
		}
	},
	setup() {
		const { skin, skinClasses } = useAppConfig()
		const { enableScrollToTop } = $themeConfig.layout

		// If skin is dark when initialized => Add class to body
		if (skin.value === 'dark') document.body.classList.add('dark-layout')

		// Provide toast for Composition API usage
		// This for those apps/components which uses composition API
		// Demos will still use Options API for ease
		provideToast({
			hideProgressBar: true,
			closeOnClick: false,
			closeButton: false,
			icon: false,
			timeout: 3000,
			transition: 'Vue-Toastification__fade',
		})

		// Set Window Width in store
		store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
		const { width: windowWidth } = useWindowSize()
		watch(windowWidth, val => {
			store.commit('app/UPDATE_WINDOW_WIDTH', val)
		})

		return {
			skinClasses,
			enableScrollToTop,
		}
	},
}
</script>
